import React from 'react';
import '../../styles/floatingbutton.css'; 
const FloatingButton = () => {
  return (
    <div className="floating-button">
      <a href="https://api.whatsapp.com/send?phone=6281395428866" target="_blank" rel="noopener noreferrer">
        <img src="wa.png" alt="WhatsApp" width={"500px"} />
      </a>
    </div>
  );
};

export default FloatingButton;