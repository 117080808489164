import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

require("highcharts/modules/wordcloud")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
const HightChartPartial = props => {
    
  if (props.topIsu === null) {
    return <h3>Loading...</h3>;
  } else {
    const titles = props.topIsu;
    const judul = props.judul;
    const startDate = props.startDate;
    const endDate = props.endDate;
    const endPoint = props.endPoint;
    const options = {
      accessibility: {
        screenReaderSection: {
          beforeChartFormat:
            "<h5>{chartTitle}</h5>" +
            "<div>{chartSubtitle}</div>" +
            "<div>{chartLongdesc}</div>" +
            "<div>{viewTableButton}</div>"
        }
      },
      legend: {
        align: "center",
        verticalAlign: "top",
        floating: true,
        x: 0,
        y: 0
      },
      credits: { enabled: false },
      series: [
        {
          type: "wordcloud",
          data: titles.slice(0, 15),
          name: "Total Mention",
          rotation: { from: 0, to: 0 },
          minFontSize: 2,
          wordSpaces: 14,
          style: { fontFamily: "Poppins" },
          events: {
            click: function(event) {
              // alert(
              //   event.point.name + ' clicked\n'
              // );
              if (endPoint === "wilayah") {
                const url = `/detail-index?data=${event.point
                  .name}&start=${startDate}&end=${endDate}`;
                const target = "_blank";
                window.open(url, target);
              } else if (endPoint === "eksekutif") {
                const url = `/detail-eksekutif?data=${event.point
                  .name}&start=${startDate}&end=${endDate}`;
                const target = "_blank";
                window.open(url, target);
              } else if (endPoint === "legislatif") {
                const url = `/detail-legislatif?data=${event.point
                  .name}&start=${startDate}&end=${endDate}`;
                const target = "_blank";
                window.open(url, target);
              } else if (endPoint === "yudikatif") {
                const url = `/detail-yudikatif?data=${event.point
                  .name}&start=${startDate}&end=${endDate}`;
                const target = "_blank";
                window.open(url, target);
              } else if (endPoint === "parpol") {
                const url = `/detail-parpol?data=${event.point
                  .name}&start=${startDate}&end=${endDate}`;
                const target = "_blank";
                window.open(url, target);
              } else {
                const url = `/detail-caleg?data=${event.point
                  .name}&start=${startDate}&end=${endDate}`;
                const target = "_blank";
                window.open(url, target);
              }
            }
          }
        }
      ],
      title: { text: judul, align: "left" },
      subtitle: { text: "Top Mention berita", align: "left" },
      tooltip: {
        headerFormat:
          '<span style="font-size: 16px"><b>{point.key}</b></span><br>'
      }
    };
    return (
      <div>
        {titles && titles.length > 0 &&
          (<HighchartsReact highcharts={Highcharts} options={options} />)
        }
      </div>
    );
  }
};
export default HightChartPartial;
