import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Executive from "./pages/Executive";
import Legislative from "./pages/Legislative";
import Yudicative from "./pages/Yudicative";
import Parpol from "./pages/Parpol";
import Caleg from "./pages/Caleg";
import Login from "./pages/Login";
import Testing from "./pages/Testing";
import Cart from "./pages/Cart";
import Index from "./pages/Index";
import ListLegislatif from "./components/Listgroup/ListLegislatif";
import ListEksekutif from "./components/Listgroup/ListEksekutif";
import ListYudikatif from "./components/Listgroup/ListYudikatif";
import ListParpol from "./components/Listgroup/ListParpol";
import ListCaleg from "./components/Listgroup/ListCaleg";
import ListIndex from "./components/Listgroup/ListIndex";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/executive" element={<Executive />} />
      <Route path="/legislative" element={<Legislative />} />
      <Route path="/yudicative" element={<Yudicative />} />
      <Route path="/parpol" element={<Parpol />} />
      <Route path="/caleg" element={<Caleg />} />
      <Route path="/login" element={<Login />} />
      <Route path="/testing" element={<Testing />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/detail-legislatif" element={<ListLegislatif />} />
      <Route path="/detail-eksekutif" element={<ListEksekutif />} />
      <Route path="/detail-yudikatif" element={<ListYudikatif />} />
      <Route path="/detail-parpol" element={<ListParpol />} />
      <Route path="/detail-caleg" element={<ListCaleg />} />
      <Route path="/detail-index" element={<ListIndex />} />
    </Routes>
  );
}

export default App;
