import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import { getChartData } from "../../helpers/processCardData";

const MediaShareChart = props => {
  const medias = props.mediaShare;
  if (medias === null) {
    <h3>Loading...</h3>;
  } else {
    const chartData = getChartData(medias);
    const data = {
      labels: chartData.labels,
      datasets: [
        {
          label: "",
          backgroundColor: [
            "#fef102",
            "#fca205",
            "#f48625",
            "#ee1c25",
            "#a81a92",
            "#5c3296",
            "#22419c",
            "#007ad7",
            "#00c0c1",
            "#03a762"
          ],
          borderColor: "rgb(255,255,255)",
          data: chartData.dataset
        }
      ]
    };

    return (
      <div>
        {medias && medias.length > 0 && <Pie data={data} />}
      </div>
    );
  }
};
export default MediaShareChart;
