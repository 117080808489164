import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";

require("highcharts/modules/wordcloud")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
const text =
    "Chapter 1. Down the Rabbit-Hole " +
    "Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do: " +
    "once or twice she had peeped into the book her sister was reading, but it had no pictures or conversations " +
    "in it, 'and what is the use of a book,' thought Alice 'without pictures or conversation?'" +
    "So she was considering in her own mind (as well as she could, for the hot day made her feel very sleepy " +
    "and stupid), whether the pleasure of making a daisy-chain would be worth the trouble of getting up and picking " +
    "the daisies, when suddenly a White Rabbit with pink eyes ran close by her.",
  lines = text.replace(/[():'?0-9]+/g, "").split(/[,\. ]+/g),
  databigram = lines.reduce((arr, word) => {
    let obj = Highcharts.find(arr, obj => obj.name === word);
    if (obj) {
      obj.weight += 1;
    } else {
      obj = {
        name: word,
        weight: 1
      };
      arr.push(obj);
    }
    return arr;
  }, []);
const Cart = props => {
  const [fetched, setFetched] = useState(false);
  const [titles, setTitles] = useState([]);
  useEffect(() => {
    if (!fetched) {
      fetchTitle();
    }
  });
  var current = new Date();
  current.setDate(current.getDate());
  // var end = current.toISOString().substring(0, 10);
  // var start = current.toISOString().substring(0, 10);
  var end = props.endDate;
  var start = props.startDate;

  const fetchTitle = async () => {
    var startDate = start;
    var endDate = end;
    const postData = { startDate: startDate, endDate: endDate };
    setFetched(true);
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/home`,
      postData
    );
    const title = response.data.data;
    const coba = title.map(item => {
      return { name: item.normal, weight: item.count };
    });
    setTitles(coba);
  };
  console.log(titles);
  const options = {
    accessibility: {
      screenReaderSection: {
        beforeChartFormat:
          "<h5>{chartTitle}</h5>" +
          "<div>{chartSubtitle}</div>" +
          "<div>{chartLongdesc}</div>" +
          "<div>{viewTableButton}</div>"
      }
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      floating: true,
      x: 0,
      y: 0
    },
    credits: {
      enabled: false
    },
    series: [
      {
        type: "wordcloud",
        data: titles,
        name: "Total Mention",
        rotation: {
          from: 0,
          to: 0
        },
        minFontSize: 2,
        wordSpaces: 14,

        style: {
          fontFamily: "Poppins"
        }
      }
    ],
    title: {
      text: "TOP 30 Title",
      align: "left"
    },
    subtitle: {
      text: "Top Mention berita",
      align: "left"
    },
    tooltip: {
      headerFormat:
        '<span style="font-size: 16px"><b>{point.key}</b></span><br>'
    }
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
export default Cart;
