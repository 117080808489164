// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#body{
  background-color: #FFFFFF;
}
.bg-filterdate {
  color: #008080; 
  background-color: #008080;
  border-color: #008080;
  margin-top: -1mm;
  padding-top: 1mm;
  padding-bottom: 1mm;
}

.card-top-title{
    color: #008080;
    background-color: #008080;
    border-color: #008080; 
}

.card-media-share{
    color: #008080;
    background-color: #008080; 
    border-color: #008080; 
}


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Menyesuaikan tinggi dengan tinggi layar */
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/styles/backgroud.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,yBAAyB;EACzB,qBAAqB;EACrB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;;;AAGA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,4CAA4C;AAC7D;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["#body{\n  background-color: #FFFFFF;\n}\n.bg-filterdate {\n  color: #008080; \n  background-color: #008080;\n  border-color: #008080;\n  margin-top: -1mm;\n  padding-top: 1mm;\n  padding-bottom: 1mm;\n}\n\n.card-top-title{\n    color: #008080;\n    background-color: #008080;\n    border-color: #008080; \n}\n\n.card-media-share{\n    color: #008080;\n    background-color: #008080; \n    border-color: #008080; \n}\n\n\n.loading-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; /* Menyesuaikan tinggi dengan tinggi layar */\n}\n\n.loading-text {\n  font-size: 24px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
