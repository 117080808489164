import { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  ListGroup,
  Image,
  Row,
  Col,
  ProgressBar
} from "react-bootstrap";
import axios from "axios";

const TabsHome = props => {
  const [key, setKey] = useState("tokoh");

  const [persons, setPersons] = useState(props.accordionTokoh);
  const [legislatif, setLegislatif] = useState(props.accordionLegislatif);
  const [eksekutif, setEksekutif] = useState(props.accordionEksekutif);

  const handleSelect = key => {
    switch (key) {
      case "tokoh":
        // fetchPersons();
        break;
      case "eksekutif":
        // fetchExecutive();
        break;
      case "legislatif":
        // fetchLegislative();
        break;
      default:
        break;
    }
    setKey(key);
  };

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={handleSelect}
      className="mb-3"
    >
      <Tab eventKey="tokoh" title="Tokoh">
        <ListGroup className="m-1">
          {/* {persons.map(person =>
            <ListGroup.Item key={person.label}>
              <Row>
                <Col xs={3}>
                  <Image
                    src={`http://ekspose.smartsearch.id/${person.foto}`}
                    width={"55px"}
                    rounded
                  />
                </Col>
                <Col>
                  <span>
                    <h6>{person.label}</h6>
                    <ProgressBar
                      now={person.persen}
                      label={`${person.persen}%`}
                    />
                    {person.total} Berita
                  </span>
                </Col>
              </Row>
            </ListGroup.Item>
          )} */}
        </ListGroup>
      </Tab>
      <Tab eventKey="executive" title="Executive">
        <ListGroup className="m-1">
          {/* {eksekutif.map(eksekutif =>
            <ListGroup.Item key={eksekutif.kategori}>
              <Row>
                <Col>
                  <span>
                    <h6>{eksekutif.kategori}</h6>
          {eksekutif.total} Berita
                  </span>
                </Col>
              </Row>
            </ListGroup.Item>
          )} */}
        </ListGroup>
      </Tab>
      <Tab eventKey="legislative" title="Legislative">
        <ListGroup className="m-1">
          {/* {legislatif.map(legislatif =>
            <ListGroup.Item key={legislatif.kategori}>
              <Row>
                <Col>
                  <span>
                    <h6>{legislatif.kategori}</h6>
                    {legislatif.total} Berita
                  </span>
                </Col>
              </Row>
            </ListGroup.Item>
          )} */}
        </ListGroup>
      </Tab>
    </Tabs>
  );
};

export default TabsHome;
