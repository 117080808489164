// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  background-color: #fff; /* Putih */
}

#bg-col-login{
    align-content: center;
    align-items: center;
}

h4{
    font-size: 50pt;
    font-family: 'Courier New', Courier, monospace;    
}


h1{
    margin-top: -40px;
    font-size: 90pt;
    font-family: 'Times New Roman', Times, serif;
}

@media (max-width: 768px) {
  h4 {
    display: none;
  }

  h1{
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB,EAAE,UAAU;AACpC;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,8CAA8C;AAClD;;;AAGA;IACI,iBAAiB;IACjB,eAAe;IACf,4CAA4C;AAChD;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":["body, html {\n  background-color: #fff; /* Putih */\n}\n\n#bg-col-login{\n    align-content: center;\n    align-items: center;\n}\n\nh4{\n    font-size: 50pt;\n    font-family: 'Courier New', Courier, monospace;    \n}\n\n\nh1{\n    margin-top: -40px;\n    font-size: 90pt;\n    font-family: 'Times New Roman', Times, serif;\n}\n\n@media (max-width: 768px) {\n  h4 {\n    display: none;\n  }\n\n  h1{\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
