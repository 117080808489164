import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Bar } from "react-chartjs-2";

const BarChartEksekutif = props => {
  const kategori = props.katBarChartEksekutif;
  const jumlah = props.jumBarChartEksekutif;
  const judul = props.judul;
  const startDate = props.startDate;
  const endDate = props.endDate;
  const [clickedData, setClickedData] = useState(null);
  const data = {
    labels: kategori,
    datasets: [
      {
        label: "Data Eksekutif",
        data: jumlah,
        backgroundColor: [
          "rgba(5,205,202,1)",
          "rgba(253,190,0,1)",
          "rgba(89,153,214,1)",
          "rgba(111,174,69,1)",
          "rgba(237,126,54,1)",
          "rgba(26,117,122,1)",
          "rgba(114,46,159,1)"
        ],
        borderColor: [
          "rgba(5,205,202,1)",
          "rgba(253,190,0,1)",
          "rgba(89,153,214,1)",
          "rgba(111,174,69,1)",
          "rgba(237,126,54,1)",
          "rgba(26,117,122,1)",
          "rgba(114,46,159,1)"
        ],
        borderWidth: 1
      }
    ]
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    }
    // onClick: (event, elements) => {
    //   if (elements.length > 0) {
    //     const dataIndex = elements[0].index;

    //     // Get the label of the clicked data point
    //     const label = data.labels[dataIndex];

    //     // Set the clicked label to the state
    //     window.location.href = `/detail-eksekutif?data=${label}&start=${startDate}&end=${endDate}`;
    //     // setClickedData(label);
    //   }
    // }
  };

  return (
    <Card className="p-3">
      <div>
        {kategori &&
          kategori.length > 0 &&
          <Bar data={data} options={options} />}
      </div>
    </Card>
  );
};

export default BarChartEksekutif;
