import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import "./marqueehome.css";

const marqueeVariants = {
  animate: {
    x: [0, -1035],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 20,
        ease: "linear"
      }
    }
  }
};

const MarqueeHomeTopTitle = props => {
  const titles = props.marqueeTopTitle;

  return (
    <div>
      {titles &&
        titles.length > 0 &&
        <div className="marquee" style={{ height: "28px" }}>
          <motion.div
            className="track"
            variants={marqueeVariants}
            animate="animate"
            transition={{ type: "tween", duration: 20 }}
          >
            <span
              style={{
                fontFamily: "serif",
                fontSize: 14,
                fontWeight: "normal"
              }}
            >
              {titles}
            </span>
          </motion.div>
        </div>}
    </div>
  );
};

export default MarqueeHomeTopTitle;
