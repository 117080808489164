import Navigation from "../components/Navigation/Navigation";
import { Row, Col, Card, FormControl, Button, Form } from "react-bootstrap";
import TabsHome from "../components/Tabs/TabsHome";
// import MediaShareChart from "../components/Charts/MediaShareChart";
// import TopTitle from "../components/WordCloud/TopTitle";
import "../styles/backgroud.css";
import { useEffect, useState } from "react";
import MediaShareChart from "../components/Charts/MediaShareChart";
import BarChartEksekutif from "../components/Charts/BarChartEksekutif";
import MarqueeHomeTopTitle from "../components/Marquee/MarqueeHomeTopTitle";
import MarqueeEksekutif from "../components/Marquee/MarqueeEksekutif";
import BarChartLegislatif from "../components/Charts/BarChartLegislatif";
import MarqueeLegislatif from "../components/Marquee/MarqueeLegislatif";
import MediaShareChartEksekutif from "../components/Charts/MediaShareChartEksekutif";
import MediaShareChartLegislatif from "../components/Charts/MediaShareChartLegislatif";
import Highchart from "../components/WordCloud/HightChart";

const Home = () => {
  var current = new Date();
  current.setDate(current.getDate());
  var end = current.toISOString().substring(0, 10);
  const [startDate, setStartDate] = useState(end);
  const [endDate, setEndDate] = useState(end);
  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    var current = new Date();
    current.setDate(current.getDate());
    var end = current.toISOString().substring(0, 10);
    setStartDate(end);
    setEndDate(end);
  }, []);

  const handleFormSubmit = e => {
    e.preventDefault();
    setStartDate(startDate);
    setEndDate(endDate);
    setFetched(false)
  };

  return (
    <div id="body">
      <Navigation />
      <div className="bg-filterdate">
        <Form onSubmit={handleFormSubmit}>
          <Row className="m-1 d-flex justify-content-end">
            <Col xs={5} sm={3} className="text-light">
              <FormControl
                name="start"
                id="start"
                type="date"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
              />
            </Col>
            <Col xs={5} sm={3} className="text-light">
              <FormControl
                name="end"
                id="end"
                type="date"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
              />
            </Col>
            <Col xs={2} sm={1} className="text-light">
              <Button variant="warning" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <Row className="m-2">
        <Col xs={12} sm={3}>
          <Card>
            <TabsHome startDate={startDate} endDate={endDate} isFetched={fetched} />
          </Card>
        </Col>
        <Col xs={12} sm={9}>
          <Row>
            <Col lg={8}>
              {/* <TopTitle startDate={startDate} endDate={endDate} /> */}
              <Highchart startDate={startDate} endDate={endDate} isFetched={fetched} />
              <Card>
                <MarqueeHomeTopTitle startDate={startDate} endDate={endDate} isFetched={fetched} />
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <Card.Header className="card-media-share text-light">
                  TOP 10 Media Share
                </Card.Header>
                <Card.Body>
                  <MediaShareChart startDate={startDate} endDate={endDate}  isFetched={fetched}/>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={8}>
              <BarChartEksekutif startDate={startDate} endDate={endDate} isFetched={fetched} />
              <Card>
                <MarqueeEksekutif startDate={startDate} endDate={endDate} isFetched={fetched} />
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <Card.Header className="card-media-share text-light">
                  TOP 10 Media Share
                </Card.Header>
                <Card.Body>
                  <MediaShareChartEksekutif
                    startDate={startDate}
                    endDate={endDate}
                    isFetched={fetched}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={8}>
              <BarChartLegislatif startDate={startDate} endDate={endDate} isFetched={fetched} />
              <Card>
                <MarqueeLegislatif startDate={startDate} endDate={endDate} isFetched={fetched} />
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <Card.Header className="card-media-share text-light">
                  TOP 10 Media Share
                </Card.Header>
                <Card.Body>
                  <MediaShareChartLegislatif
                    startDate={startDate}
                    endDate={endDate}
                    isFetched={fetched}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
