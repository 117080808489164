import { Form, Card, Container, Row, Col, Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import "../styles/login.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import FloatingButton from "../components/Button/FloatButton";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      window.location.href = "/";
    }
  }, []);

  // const [validation, setValidation] = useState([]);

  const loginHandler = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new URLSearchParams();

    formData.append("username", username);
    formData.append("grant_type", "password");
    formData.append("password", password);

    // console.log(`bigrams-client:bigrams-secret`);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/oauth/token`,
        formData,
        {
          auth: {
            username: "bigrams-client",
            password: "bigrams-secret"
          }
        }
      );
      setLoading(false);
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.access_token);
        // localStorage.setItem('token', response.data.access_token);
        window.location.href = "/";
      } else {
        console.log("Login failed!");
      }
    } catch (error) {
      console.error("Error occurred during login:", error);
    }
  };

  if (loading === true) {
    return (
      <>
        <div className="text-center" style={{ margin: "250px" }}>
          <ProgressBar now={98} label={`Loading.. 99%`} />
        </div>
      </>
    );
  } else {
    const myComponentStyle = {
      backgroundColor: "#fff"
    };
    return (
      <div style={myComponentStyle} className="mt-5">
        <Container>
          <Row>
            <Col
              xs={0}
              sm={0}
              md={0}
              lg={6}
              id="bg-col-login"
              className="d-flex justify-content-center"
            >
              <img src="logo_name.png" alt="" width={400} />
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <form onSubmit={loginHandler}>
                <Card className="m-5 p-5">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder=""
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder=""
                    />
                  </Form.Group>
                  <Button type="submit" className="btn btn-primary mt-3">
                    Login
                  </Button>
                </Card>
              </form>
            </Col>
          </Row>
          <Row className="text-center">
            <span>Kontak Admin: 0813-9542-8866</span>
          </Row>
        </Container>
        <FloatingButton />
      </div>
    );
  }
};

export default Login;
