import React, { useState } from "react";

const Testing = () => {
  const [formValue, setFormValue] = useState("");

  const handleSubmit = event => {
    event.preventDefault();
    setFormValue(event.target.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={formValue}
          onChange={event => setFormValue(event.target.value)}
        />
        <button type="submit">Submit</button>
      </form>
      <p>
        Form Value: {formValue}
      </p>
    </div>
  );
};

export default Testing;
