// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FloatingButton.css */

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999; /* Ensure the button stays above other elements */
  width: 70px; /* Set the desired button width */
  height: 70px; /* Set the desired button height */
  border-radius: 50%;
  background-color: #25d366; /* Replace with the WhatsApp green color or your desired background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-button img {
  width: 100%; /* Set the desired size of the WhatsApp logo */
  height: auto;
}

.floating-button img:hover {
  opacity: 0.8; /* Optional: Reduce opacity on hover for visual feedback */
}
`, "",{"version":3,"sources":["webpack://./src/styles/floatingbutton.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,YAAY,EAAE,iDAAiD;EAC/D,WAAW,EAAE,iCAAiC;EAC9C,YAAY,EAAE,kCAAkC;EAChD,kBAAkB;EAClB,yBAAyB,EAAE,2EAA2E;EACtG,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW,EAAE,8CAA8C;EAC3D,YAAY;AACd;;AAEA;EACE,YAAY,EAAE,0DAA0D;AAC1E","sourcesContent":["/* FloatingButton.css */\n\n.floating-button {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  z-index: 999; /* Ensure the button stays above other elements */\n  width: 70px; /* Set the desired button width */\n  height: 70px; /* Set the desired button height */\n  border-radius: 50%;\n  background-color: #25d366; /* Replace with the WhatsApp green color or your desired background color */\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.floating-button img {\n  width: 100%; /* Set the desired size of the WhatsApp logo */\n  height: auto;\n}\n\n.floating-button img:hover {\n  opacity: 0.8; /* Optional: Reduce opacity on hover for visual feedback */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
