import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Bar } from "react-chartjs-2";

const BarChartLegislatif = props => {
  const kategori = props.katBarChartLegislatif;
  const jumlah = props.jumBarChartLegislatif;
  const judul = props.judul;
  const startDate = props.startDate;
  const endDate = props.endDate;
  const [clickedData, setClickedData] = useState(null);
  const data = {
    labels: kategori,
    datasets: [
      {
        label: judul,
        data: jumlah,
        backgroundColor: [
          "rgba(5,205,202,1)",
          "rgba(253,190,0,1)",
          "rgba(89,153,214,1)",
          "rgba(111,174,69,1)",
          "rgba(237,126,54,1)",
          "rgba(26,117,122,1)",
          "rgba(114,46,159,1)"
        ],
        borderColor: [
          "rgba(5,205,202,1)",
          "rgba(253,190,0,1)",
          "rgba(89,153,214,1)",
          "rgba(111,174,69,1)",
          "rgba(237,126,54,1)",
          "rgba(26,117,122,1)",
          "rgba(114,46,159,1)"
        ],
        borderWidth: 1
      }
    ]
  };

  const options = {
    scales: { y: { beginAtZero: true, ticks: { stepSize: 1 } } }
    // onClick: (event, elements) => {
    //   if (elements.length > 0) {
    //     const dataIndex = elements[0].index;

    //     // Get the label of the clicked data point
    //     const label = data.labels[dataIndex];

    //     // Set the clicked label to the state
    //     if (judul === "Data Legislatif") {
    //       window.location.href = `/detail-legilatif?data=${label}&start=${startDate}&end=${endDate}`;
    //     } else if (judul === "Data Yudikatif") {
    //       window.location.href = `/detail-yudikatif?data=${label}&start=${startDate}&end=${endDate}`;
    //     } else if (judul === "Data Parpol") {
    //       window.location.href = `/detail-parpol?data=${label}&start=${startDate}&end=${endDate}`;
    //     } else {
    //       window.location.href = `/detail-caleg?data=${label}&start=${startDate}&end=${endDate}`;
    //     }
    //     // setClickedData(label);
    //   }
    // }
  };

  return (
    <Card className="p-3">
      {kategori &&
        kategori.length > 0 &&
        <div>
          <Bar data={data} options={options} />
        </div>}
    </Card>
  );
};

export default BarChartLegislatif;
