import axios from "axios";
import { useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useLocation, useParams } from "react-router-dom";
import "../../styles/backgroud.css";

const ListCaleg = () => {
  const [fetched, setFetched] = useState();
  const [data, setData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const labelData = searchParams.get("data");
  const startDate = searchParams.get("start");
  const endDate = searchParams.get("end");
  const bearerToken = sessionStorage.getItem("token");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const postData = { startDate: startDate, endDate: endDate, search: labelData};
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/caleg/search`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        }
      );
      setFetched(true);
      const datas = await response.data.data;
      setData(datas);
    } catch (error) {
        console.log(error);
    }
  };

  return (
    <Card className="m-5">
      <CardHeader className="text-light card-media-share">
        <h5>Daftar Berita { labelData } </h5>
      </CardHeader>
      <ListGroup>
        {/* {console.log(data)} */}
        {data.map(legislatif => 
          <ListGroup.Item><a href={legislatif.link} target="_blank" rel="noopener noreferrer">{legislatif.title}</a> </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
};

export default ListCaleg;
