import { useState } from "react";
import {
  Tab,
  Tabs,
  ListGroup,
  Row,
  Col,
  Image,
  ProgressBar,
  Card
} from "react-bootstrap";

const TabsIndex = props => {
  const [key, setKey] = useState("tokoh");
  
    const accordionPersons = props.accordionPersons;
    const accordionEksekutif = props.accordionEksekutif;
    const accordionLegistif = props.accordionLegislatif;
    const handleSelect = key => {
      switch (key) {
        case "tokoh":
          break;
        case "eksekutif":
          break;
        case "legislatif":
          break;
        default:
          break;
      }
      setKey(key);
    };
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={handleSelect}
        className="mb-3"
      >
        <Tab eventKey="tokoh" title="Tokoh">
           {accordionPersons && accordionPersons.length > 0 && (
          <ListGroup className="m-1">
            {accordionPersons.map(person =>
              <ListGroup.Item>
                <Row>
                  <Col xs={3}>
                    <Image
                      src={`http://ekspose.smartsearch.id/${person.foto}`}
                      width={"55px"}
                      roundedCircle
                    />
                  </Col>
                  <Col>
                    <span>
                      <h6>{person.label}</h6>
                      <ProgressBar
                        now={person.persen}
                        label={`${person.persen}%`}
                      />
                      {person.total} Berita
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
          </ListGroup>
           )}
        </Tab>
        <Tab eventKey="executive" title="Executive">
          {accordionEksekutif && accordionEksekutif.length > 0 && (
          <ListGroup className="m-1">
            {
              accordionEksekutif.map(eksekutif =>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <span>
                      <h6>{eksekutif.kategori}</h6>
                      {eksekutif.total} Berita
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
            ) 
            }
          </ListGroup>
          )}
        </Tab>
        <Tab eventKey="legislative" title="Legislative">
          {accordionLegistif && accordionLegistif.length > 0 && (
          <ListGroup className="m-1">
            {accordionLegistif.map(legislatif =>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <span>
                      <h6>{legislatif.kategori}</h6>
                      {legislatif.total} Berita
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
          </ListGroup>
          )}
        </Tab>
      </Tabs>
    );
  }

export default TabsIndex;
