import axios from "axios";
import Navigation from "../components/Navigation/Navigation";
import {
  Row,
  Col,
  Card,
  FormControl,
  Button,
  Form,
  Container
} from "react-bootstrap";
import "../styles/backgroud.css";
import { useEffect, useState } from "react";
import MediaShareChart from "../components/Charts/MediaShareChart";
import BarChartEksekutif from "../components/Charts/BarChartEksekutif";
import MarqueeHomeTopTitle from "../components/Marquee/MarqueeHomeTopTitle";
import MarqueeEksekutif from "../components/Marquee/MarqueeEksekutif";
import MediaShareChartEksekutif from "../components/Charts/MediaShareChartEksekutif";
import TabsIndex from "../components/Tabs/TabsIndex";
import HighchartEksekutif from "../components/WordCloud/HightChartPartial";

const Executive = () => {
  var current = new Date();
  current.setDate(current.getDate());
  var end = current.toISOString().substring(0, 10);
  const [startDate, setStartDate] = useState(end);
  const [endDate, setEndDate] = useState(end);
  const bearerToken = sessionStorage.getItem("token");

  const [fetched, setFetched] = useState();
  const [topIsu, setTopIsu] = useState(null);
  const [accordionEksekutif, setaccordionEksekutif] = useState(null);
  const [accordionLegislatif, setaccordionLegislatif] = useState(null);
  const [accordionPersons, setaccordionPersons] = useState(null);
  const [mediaShare, setMediaShare] = useState(null);
  const [katBarChartEksekutif, setKatBarChartEksekutif] = useState(null);
  const [jumBarChartEksekutif, setJumBarChartEksekutif] = useState(null);
  const [marqueeEksekutif, setMarqueeEksekutif] = useState(null);
  const [mediaShareEksekutif, setMediaShareEksekutif] = useState(null);
  const [loading, setLoading] = useState(true);
  const [marqueeTopTitle, setMarqueeTopTitle] = useState(null);

  useEffect(() => {
    if (!bearerToken) {
      window.location.href = "/login";
    }
    setLoading(true);
    var current = new Date();
    current.setDate(current.getDate());
    var end = current.toISOString().substring(0, 10);
    setStartDate(end);
    setEndDate(end);
    highChartData();
    tabsEksekutif();
    tabsLegislatif();
    tabsTokoh();
    getMediaShare();
    getMarqueeTopTitle();
    getBarChartEksekutif();
    getMarqueeEksekutif();
    getMediaShareEksekutif();
    setLoading(false);
  }, []);

  const handleFormSubmit = e => {
    e.preventDefault();
    setLoading(true);
    setStartDate(startDate);
    setEndDate(endDate);
    highChartData();
    tabsEksekutif();
    tabsLegislatif();
    tabsTokoh();
    getMediaShare();
    getMarqueeTopTitle();
    getBarChartEksekutif();
    getMarqueeEksekutif();
    getMediaShareEksekutif();
    setLoading(false);
  };

  const getMediaShareEksekutif = async () => {
    try {
      const postData = { startDate: startDate, endDate: endDate };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/eksekutif/quantity-media`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        }
      );
      setFetched(true);
      const media = await response.data.data;
      setMediaShareEksekutif(media);
    } catch (error) {
      console.log(error);
    }
  };

  const getMarqueeEksekutif = async () => {
    try {
      const postData = { startDate: startDate, endDate: endDate, nlp: false };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/eksekutif`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        }
      );
      setFetched(true);
      const title = response.data.data;

      const c = [];
      title.map(item => {
        return c.push(item.title + " | ");
      });
      setMarqueeEksekutif(c);
    } catch (error) {
      console.log(error);
    }
  };

  const getBarChartEksekutif = async () => {
    try {
      const postData = { startDate: startDate, endDate: endDate };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/eksekutif/quantity`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        }
      );
      setFetched(true);
      const kat = [];
      const total = [];

      const data = response.data.data;
      data.map(item => kat.push(item.kategori));

      data.map(item => total.push(item.total));
      setKatBarChartEksekutif(kat);
      setJumBarChartEksekutif(total);
    } catch (error) {
      console.log(error);
    }
  };

  const getMarqueeTopTitle = async () => {
    try {
      const postData = { startDate: startDate, endDate: endDate, nlp: false };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/eksekutif`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        }
      );
      setFetched(true);
      const title = response.data.data;

      const c = [];
      title.map(item => {
        return c.push(item.title + " | ");
      });
      setMarqueeTopTitle(c);
    } catch (error) {
      console.log(error);
    }
  };

  const getMediaShare = async () => {
    const postData = { startDate: startDate, endDate: endDate };
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/eksekutif/nlp-media`,
      postData,
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      }
    );
    const media = response.data.data;
    setMediaShare(media);
  };

  //   get highchart data
  const highChartData = async () => {
    try {
      const postData = { startDate: startDate, endDate: endDate, nlp: true };
      setFetched(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/eksekutif`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        }
      );
      const title = response.data.data;
      const coba = title.map(item => {
        return { name: item.normal, weight: item.count };
      });
      setTopIsu(coba);
    } catch (error) {
      console.log(error);
    }
  };
  // end highChartData

  //   get data tabs home
  const tabsEksekutif = async () => {
    try {
      const postData = { startDate: startDate, endDate: endDate };
      const url = `${process.env.REACT_APP_API_ENDPOINT}/eksekutif/anggota`;
      const response = await axios.post(url, postData, {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      });
      const { data } = response.data;
      setaccordionEksekutif(data);
    } catch (error) {
      console.log(error);
    }
  };

  const tabsLegislatif = async () => {
    try {
      const postData = { startDate: startDate, endDate: endDate };
      const url = `${process.env.REACT_APP_API_ENDPOINT}/legislatif/anggota`;
      const response = await axios.post(url, postData, {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      });
      const { data } = response.data;
      console.log(data);
      setaccordionLegislatif(data);
    } catch (error) {
      console.log(error);
    }
  };

  const tabsTokoh = async () => {
    try {
      const postData = { startDate: startDate, endDate: endDate };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/person/tokoh`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        }
      );
      const { data } = response.data;
      setaccordionPersons(data);
    } catch (error) {
      console.log(error);
    }
  };
  // end data tabs home
  // console.log(loading);
  
    return (
      <div id="body">
        <Navigation />
        <div className="bg-filterdate">
          <Form onSubmit={handleFormSubmit}>
            <Row className="m-1 d-flex justify-content-end">
              <Col xs={4} sm={5} md={3} lg={3} className="text-light">
                <FormControl
                  name="start"
                  id="start"
                  type="date"
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                />
              </Col>
              <Col xs={4} sm={5} md={3} lg={3} className="text-light">
                <FormControl
                  name="end"
                  id="end"
                  type="date"
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                />
              </Col>
              <Col xs={4} sm={2} md={2} lg={2} className="text-light">
                <Button variant="warning" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <Row className="m-2">
          <Col xs={12} sm={3}>
            <Card>
              <TabsIndex
                accordionEksekutif={accordionEksekutif}
                accordionLegislatif={accordionLegislatif}
                accordionPersons={accordionPersons}
              />
            </Card>
          </Col>
          <Col xs={12} sm={9}>
            <Row>
              <Col lg={8}>
                <HighchartEksekutif
                  topIsu={topIsu}
                  judul={"Top Trend Isu Eksekutif"}
                  startDate={startDate}
                  endDate={endDate}
                  endPoint={"eksekutif"}
                />
                <Card>
                  <MarqueeHomeTopTitle marqueeTopTitle={marqueeTopTitle} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <Card.Header className="card-media-share text-light">
                    TOP 10 Media Share
                  </Card.Header>
                  <Card.Body>
                    <MediaShareChart mediaShare={mediaShare} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={8}>
                <BarChartEksekutif
                  katBarChartEksekutif={katBarChartEksekutif}
                  jumBarChartEksekutif={jumBarChartEksekutif}
                  startDate={startDate}
                  endDate={endDate}
                />
                <Card>
                  <MarqueeEksekutif marqueeEksekutif={marqueeEksekutif} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <Card.Header className="card-media-share text-light">
                    TOP 10 Media Share
                  </Card.Header>
                  <Card.Body>
                    <MediaShareChartEksekutif
                      mediaShareEksekutif={mediaShareEksekutif}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

export default Executive;
