// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 50px;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
}

.track > h1 {
  margin: 20px 0;
  font-size: 0.7em;
  font-family: 'Courier New', Courier, monospace;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #47A992;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/components/Marquee/marqueehome.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,8CAA8C;EAC9C,+CAA+C;EAC/C,8BAA8B;EAC9B,kCAAkC;EAClC,yBAAyB;AAC3B","sourcesContent":[".marquee {\n  position: relative;\n  width: 100vw;\n  max-width: 100%;\n  height: 50px;\n  overflow-x: hidden;\n}\n\n.track {\n  position: absolute;\n  white-space: nowrap;\n}\n\n.track > h1 {\n  margin: 20px 0;\n  font-size: 0.7em;\n  font-family: 'Courier New', Courier, monospace;\n  -webkit-text-fill-color: rgba(255, 255, 255, 0);\n  -webkit-text-stroke-width: 2px;\n  -webkit-text-stroke-color: #47A992;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
