import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const Navigation = () => {
  const logoutHanlder = async () => {
    sessionStorage.removeItem("token");
    window.location.href = "/login";
  };

  return (
    <Navbar style={{ backgroundColor: "#000000" }} expand="lg">
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className="text-light"
        style={{ backgroundColor: "#FFF", marginLeft: "20px" }}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link className="text-light" href="/">
            Beranda
          </Nav.Link>
          <Nav.Link className="text-light" href="/executive">
            Eksekutif
          </Nav.Link>
          <Nav.Link className="text-light" href="/legislative">
            Legislatif
          </Nav.Link>
          <Nav.Link className="text-light" href="/yudicative">
            Yudikatif
          </Nav.Link>
          <Nav.Link className="text-light" href="/parpol">
            Parpol
          </Nav.Link>
          <Nav.Link className="text-light" href="/caleg">
            Caleg
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <button
        onClick={logoutHanlder}
        style={{ marginRight: "20px" }}
        className="btn btn-md btn-danger"
      >
        LOGOUT
      </button>
    </Navbar>
  );
};

export default Navigation;
