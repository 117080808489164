export const getChartData = (datas) => {
    const labels = [];
    const dataset = [];
    if (typeof datas !== 'undefined' && datas?.length > 0) {
      datas.map((media, index) => labels.push(media.nama_media));
      datas.map((media, index) => dataset.push(media.total));
    }
    return {
        labels : labels,
        dataset : dataset
    }
}